/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React,{useContext,useEffect} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRoute } from '../config/route-config';
//import SearchResult from '../components/customerComponent/SearchCustomerResult';
//import { Form } from '../components/customerComponent/customerValidation';
import { GetSalesLeadExpirationDays } from '../api/user';
//import { CustomerContextProvider } from '../context/customerContext/customerContext';
//import { ManageCustomerInfo } from '../components/manageCustomer/ManageCustomer';
import CustomerUpdate from '../components/manageCustomer/ManageCustomer';
import SearchResultRacstrap from '../racstrapComponent/customerComponent/SearchCustomerResult';
import { FormRacstrap } from '../racstrapComponent/customerComponent/customerValidation';
import { ManageCustomerInfoRacstrap } from '../racstrapComponent/manageCustomer/ManageCustomer';
import {
  FeatureFlagContext,
  MakeFeatureFlagObjFn,
} from '../context/FeatureFlagContext';
import { DeliveryContextData } from '../context/DeliveryContext';
export const routesTestId = 'routesTestId';

export const Routes = () => {
  const { setFeatureFlagDetails } = useContext(FeatureFlagContext);
  useEffect(() => {
    const featureFlagCall = async () => {
      const featureFlagReq = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: ['CustomerOrder', 'SplitAgreement', 'AgreementTransfer', 'PromoAgreement','DiscountedAgreement'],
      };
      const FeatureFlagResponse = await GetSalesLeadExpirationDays(
        featureFlagReq
      );
      // eslint-disable-next-line no-console
      console.log('FeatureFlagResponse', FeatureFlagResponse);
      if (FeatureFlagResponse.status == 200) {
        const FeatureFlagObj = MakeFeatureFlagObjFn(
          FeatureFlagResponse?.data?.storeProfileResponse.configDetails[0]
            .configDetails
        );
        setFeatureFlagDetails(FeatureFlagObj);
      }
    };
    featureFlagCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div data-testid={routesTestId}>
      <DeliveryContextData>
        <Switch>
          <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.SearchResult} />
          </Route>
          {/* <Route exact path={AppRoute.Placeholder1}>
            <Placeholder1 />
          </Route>
          <Route exact path={AppRoute.Placeholder2}>
            <Placeholder2 />
          </Route> */}
          {/* <Route path={AppRoute.Customer}>
            <CustomerRoute />
          </Route> */}
          <Route
            exact
            path={AppRoute.SearchResult}
            key="/customer/searchcustomer"
            component={SearchResultRacstrap}
          />
          <Route
            exact
            path={AppRoute.UpdateCustomer}
            key="/customer/updateCustomer"
            component={CustomerUpdate}
          />
          <Route
            exact
            path={AppRoute.CreateCustomer}
            key="/customer/createcustomer"
            component={FormRacstrap}
          />
          {/* <Route
            exact
            path={AppRoute.CreateCustomerInfo}
            key="/createcustomer/customervalidation"
            component={Info}
          /> */}
          <Route
            exact
            path={AppRoute.GetCustomerById}
            key="/customer/createcustomer/:customerId/:pageType"
            component={ManageCustomerInfoRacstrap}
          />
          <Route
            exact
            path={AppRoute.UpdateCustomerById}
            key="/customer/update/:customerId/:pageType"
            component={ManageCustomerInfoRacstrap}
          />
          {/*Racstrap */}
          <Route
            exact
            path={AppRoute.SearchResultRacstrap}
            key="/customer/searchcustomer"
            component={SearchResultRacstrap}
          />
          <Route
            exact
            path={AppRoute.CreateCustomerRacstrap}
            key="/customer/searchcustomer"
            component={FormRacstrap}
          />
          <Route
            exact
            path={AppRoute.UpdateCustomerByIdRacstrap}
            key="/customer/searchcustomer"
            component={ManageCustomerInfoRacstrap}
          />{' '}
          <Route
            exact
            path={AppRoute.GetCustomerByIdRacstrap}
            key="/customer/searchcustomer"
            component={ManageCustomerInfoRacstrap}
          />
        </Switch>
        </DeliveryContextData>

    </div>
  );
};
