/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { makeStyles } from '@rentacenter/racstrap';
export const fieldDeviceStyles = () => {
  const useClasses = makeStyles((theme) => ({
  
    [theme.breakpoints.between(800, 1281)]: {
    //display styles starts 
    dMdNone: {
        display: 'none !important',
      },
      dMdBlock: {
        display: 'block !important',
      },
      dMdFlex: {
        display: 'flex !important',
      },
      //display styles ends 
      // table styles starts 
      upTableHead:{
        '&:nth-last-child(-n+3)': {
            display: 'none',
          },
          '&:nth-child(5)':{
            display: 'none',
          },
          '&:nth-child(6)':{
            display: 'none',
          },
          '&:nth-child(9)':{
            display: 'none',
          },
          '&:nth-child(10)':{
            display: 'none',
          },
          '&:nth-child(11)':{
            display: 'none',
          },
      },
      upTableRow:{
       '& td':{
        '&:nth-last-child(-n+3)': {
            display: 'none',
          },
          '&:nth-child(5)':{
            display: 'none',
          },
          '&:nth-child(6)':{
            display: 'none',
          },
          '&:nth-child(9)':{
            display: 'none',
          },
          '&:nth-child(10)':{
            display: 'none',
          },
          '&:nth-child(11)':{
            display: 'none',
          },
       }
      },
      agreementGrid:{
        '& thead': {
            '& tr': {
              '& th': {
                '&:nth-child(3)':{
                    display: 'none',
                  },
                  '&:nth-child(4)':{
                    display: 'none',
                  },
                '&:nth-child(5)':{
                    display: 'none',
                  },
                  '&:nth-child(6)':{
                    display: 'none',
                  },
                  '&:nth-child(9)':{
                    display: 'none',
                  },
                  '&:nth-child(10)':{
                    display: 'none',
                  },
              },
            },
          },
          '& tbody': {
            '& tr': {
              '& td': {
                '&:nth-child(3)':{
                    display: 'none',
                  },
                  '&:nth-child(4)':{
                    display: 'none',
                  },
                '&:nth-child(5)':{
                    display: 'none',
                  },
                  '&:nth-child(6)':{
                    display: 'none',
                  },
                  '&:nth-child(9)':{
                    display: 'none',
                  },
                  '&:nth-child(10)':{
                    display: 'none',
                  },
  
              },
            },
          },
      }
      // table styles ends 
    },
    [theme.breakpoints.between(599, 801)]: {
    //display styles starts 
    dMdNone: {
        display: 'none !important',
      },
      dMdBlock: {
        display: 'block !important',
      },
      dMdFlex: {
        display: 'flex !important',
      },
      //display styles ends 
        upTableHead:{
            '&:nth-last-child(-n+3)': {
                display: 'none',
              },
              '&:nth-child(5)':{
                display: 'none',
              },
              '&:nth-child(6)':{
                display: 'none',
              },
              '&:nth-child(7)':{
                display: 'none',
              },
              '&:nth-child(8)':{
                display: 'none',
              },
              '&:nth-child(9)':{
                display: 'none',
              },
              '&:nth-child(10)':{
                display: 'none',
              },
              '&:nth-child(11)':{
                display: 'none',
              },
          },
          upTableRow:{
           '& td':{
            '&:nth-last-child(-n+3)': {
                display: 'none',
              },
              '&:nth-child(5)':{
                display: 'none',
              },
              '&:nth-child(6)':{
                display: 'none',
              },
              '&:nth-child(7)':{
                display: 'none',
              },
              '&:nth-child(8)':{
                display: 'none',
              },
              '&:nth-child(9)':{
                display: 'none',
              },
              '&:nth-child(10)':{
                display: 'none',
              },
              '&:nth-child(11)':{
                display: 'none',
              },
           }
          },
          agreementGrid:{
            '& thead': {
                '& tr': {
                  '& th': {
                    '&:nth-child(3)':{
                        display: 'none',
                      },
                      '&:nth-child(4)':{
                        display: 'none',
                      },
                    '&:nth-child(5)':{
                        display: 'none',
                      },
                      '&:nth-child(6)':{
                        display: 'none',
                      },
                      '&:nth-child(7)':{
                        display: 'none',
                      },
                      '&:nth-child(8)':{
                        display: 'none',
                      },
                      '&:nth-child(9)':{
                        display: 'none',
                      },
                      '&:nth-child(10)':{
                        display: 'none',
                      },
                  },
                },
              },
              '& tbody': {
                '& tr': {
                  '& td': {
                    '&:nth-child(3)':{
                        display: 'none',
                      },
                      '&:nth-child(4)':{
                        display: 'none',
                      },
                    '&:nth-child(5)':{
                        display: 'none',
                      },
                      '&:nth-child(6)':{
                        display: 'none',
                      },
                      '&:nth-child(7)':{
                        display: 'none',
                      },
                      '&:nth-child(8)':{
                        display: 'none',
                      },
                      '&:nth-child(9)':{
                        display: 'none',
                      },
                      '&:nth-child(10)':{
                        display: 'none',
                      },
      
                  },
                },
              },
          }

    },
    positionRelative:
    {
      position: 'relative'
    },
    positionAbsolute:
    {
      position: 'absolute'
    },
    hide:{
     display:"none"
    },
    actionsList: {
      bottom: '60px',
      right: '0px',
      backgroundColor: `#fff`,
      width: '227px',
      borderRadius: '8px',
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      border: '1px solid #EAF0F4',
      '& li:hover':
      {
        backgroundColor: `#DBE6FF`,
        '& a':
        {
          textDecoration: 'none !important',
          display: 'block',
          width: '100%',
          color: '#000',
        }
      },
     

    },
   
    createAgrList:{
        '& ul':{
            display: 'none',
            bottom: '0px',
            right: '228px',
            backgroundColor: `#fff`,
            width: '180px',
            borderRadius: '8px',
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            border: '1px solid #EAF0F4',
            '& li:hover':
            {
              backgroundColor: `#DBE6FF`,
              '& a':
              {
                textDecoration: 'none !important',
                display: 'block',
                width: '100%',
                color: '#000',
              }
            },
        },
      '&:hover':{
        '& ul':{
            display: 'block',
        }
      }
    },
    vNone:
    {
       visibility:'hidden'
    },

  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};